@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

* {
  font-family:
    "Pretendard Variable",
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    "Helvetica Neue",
    "Segoe UI",
    "Apple SD Gothic Neo",
    "Noto Sans KR",
    "Malgun Gothic",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    sans-serif;
}

html {
  scroll-behavior: smooth;
}

@keyframes scroll {
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.animate-scroll {
  animation: scroll 30s linear infinite;
}
.overflow-hidden {
  overflow: hidden;
}
.instagram-image {
  min-width: 20%;
  flex: 0 0 auto;
}

.slick-prev:before {
  margin-left: -40px;
  color: black;
  font-size: 40px;
  background-color: transparent;
}
.slick-next:before {
  margin-right: -40px;
  color: black;
  font-size: 40px;
  background-color: transparent;
}
@media (max-width: 768px) {
  .slick-prev:before,
  .slick-next:before {
    color: black;
    font-size: 30px;
  }
}

.slider-image {
  width: 200vh;
  height: 60vh;
  object-fit: cover;
}
.slick-dots {
  display: block;
}

.slick-slider {
  position: relative;
}
.slick-arrow {
  z-index: 1;
}
@media (max-width: 768px) {
  .thumb-wrapper {
    display: none;
  }
  .slick-dots {
    display: block;
  }
}
.fade-in-right {
  opacity: 0;
  transform: translateX(50%);
  transition:
    opacity 1.5s ease-out,
    transform 0.8s ease-out;
}
.fade-in-right.show {
  opacity: 1;
  transform: translateX(0);
}
